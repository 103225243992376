  <template>
  <div class="order-complete" v-if="result">
    <div class="order-complete-container">
      <div class="title">{{t('orderComplete.p1')}}</div>
      <div class="order-complete-body">
        <div class="order-complete-message">
          <div class="icon">
            <img
              class="order-complete-icon"
              src="@/assets/icons/checkCircleOutlined.svg"
              alt="order-complete-icon"
            />
          </div>
          <p class="message">{{t('orderComplete.p2')}}</p>
        </div>

        <a-divider type="horizontal" />

        <div class="order-complete-detail">
          <div class="detail">
            <div class="detail-item">
              <span>{{t('orderComplete.p3')}}</span>
              <p>{{ route.params.orderNumber }}</p>
            </div>
            <div class="detail-item">
              <span>{{t('orderComplete.p4')}}</span>
              <p>{{ goodsAmount }}</p>
            </div>
            <div class="detail-item">
              <span>{{t('orderComplete.p5')}}</span>
              <p>{{t('global.currency')}}{{ goodsPrice }}</p>
            </div>
          </div>
        </div>

        <div class="order-complete-button">
          <a-button type="danger" aria-label="View Order" v-if="isVisitor">
            <router-link to="/user/my-orders">{{t('orderComplete.p6')}}</router-link>
          </a-button>
          <a-button type="primary" aria-label="Continue Shopping">
            <router-link to="/">{{t('orderComplete.p7')}}</router-link>
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { Divider, Button } from 'ant-design-vue';
import {
  defineComponent, onBeforeMount, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { getOrderComplete, getVisitorOrderComplete } from '@/services/order';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'OrderComplete',
  components: {
    ...importComponents(
      Divider, Button,
    ),
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();

    const goodsAmount = ref(0);
    const goodsPrice = ref(0);
    const result = ref(false);
    const isVisitor = ref(!localStorage.getItem('touristToken'));

    const orderCompelte = async () => {
      let res;
      if (localStorage.getItem('touristToken')) {
        res = await getVisitorOrderComplete(route.params.orderNumber as string);
        localStorage.removeItem('touristToken');
      } else {
        res = await getOrderComplete(route.params.orderNumber as string);
      }
      const { success, data } = res;
      if (success) {
        result.value = true;
        goodsAmount.value = data.countOrderGoods;
        goodsPrice.value = data.orderPrice;
      }
    };

    onBeforeMount(orderCompelte);

    return {
      route,
      goodsAmount,
      goodsPrice,
      result,
      t,
      isVisitor,
    };
  },

});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import "@/assets/styles/variables.scss";
.order-complete {
  background-color: $container-background-color;
  border-bottom: 3px solid $footer-color;
  padding: 50px 0 140px 0;
  .order-complete-container {
    @include main-container();
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 0px rgba(37, 37, 37, 0.16);
    .title {
      color: $theme-color;
      font-size: 25px;
      font-weight: bold;
      line-height: 30px;
      border-bottom: 2px solid #ededed;
      padding: 49px 0 40px 20px;
    }
    .order-complete-body {
      padding: 20px 0;
      margin-bottom: 39px;
      .ant-divider,
      .ant-divider-vertical {
        height: 2px;
        color: #ededed;
        margin: 0;
      }

      .order-complete-message {
        @include flex-center();
        flex-direction: column;
        padding: 20px 0;
        .icon {
          margin: 10px 0 20px;
        }
        .message {
          padding: 20px 0;
          color: $font-color;
          line-height: 21px;
          font-size: 18px;
          font-weight: 500;
          margin-top: 2px;
        }
      }

      .order-complete-detail {
        @include flex-center();
        .detail {
          width: 60%;
          display: flex;
          justify-content: space-between;
          padding: 16px;
          margin: 23px 0 14px;
          .detail-item {
            padding: 0 10px;
            margin-left: 20px;
            span {
              font-size: 16px;
              font-weight: 400;
              color: #545454;
              line-height: 20px;
            }
            p {
              font-size: 20px;
              font-weight: 800;
              color: #1c1c1c;
              line-height: 24px;
              margin-top: 14px;
            }
          }
        }
      }

      .order-complete-button {
        @include flex-center();
        margin: 22px 0 16px;
        .ant-btn.ant-btn-primary {
          font-size: 16px;
          font-weight: bold;
          height: 48px;
          border-radius: 4px;
          background: #E65050;
          border-color: #E65050;
          margin: 23px 25px 0;
        }
        .ant-btn.ant-btn-danger {
          width: 180px;
          font-size: 16px;
          font-weight: bold;
          height: 48px;
          border-radius: 4px;
          background: #ffffff;
          border-color: #969696;
          color: #1C1C1C;
          margin: 23px 0 0 28px;
        }
      }
    }
  }
}
</style>
