
import importComponents from '@/utils/import-components';
import { Divider, Button } from 'ant-design-vue';
import {
  defineComponent, onBeforeMount, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import { getOrderComplete, getVisitorOrderComplete } from '@/services/order';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'OrderComplete',
  components: {
    ...importComponents(
      Divider, Button,
    ),
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();

    const goodsAmount = ref(0);
    const goodsPrice = ref(0);
    const result = ref(false);
    const isVisitor = ref(!localStorage.getItem('touristToken'));

    const orderCompelte = async () => {
      let res;
      if (localStorage.getItem('touristToken')) {
        res = await getVisitorOrderComplete(route.params.orderNumber as string);
        localStorage.removeItem('touristToken');
      } else {
        res = await getOrderComplete(route.params.orderNumber as string);
      }
      const { success, data } = res;
      if (success) {
        result.value = true;
        goodsAmount.value = data.countOrderGoods;
        goodsPrice.value = data.orderPrice;
      }
    };

    onBeforeMount(orderCompelte);

    return {
      route,
      goodsAmount,
      goodsPrice,
      result,
      t,
      isVisitor,
    };
  },

});
