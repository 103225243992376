/*
 * @Author: your name
 * @Date: 2021-01-26 11:19:38
 * @LastEditTime: 2021-01-26 11:43:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /web-pc/src/services/order.ts
 */
import Cookies from 'js-cookie';
import http from '@/utils/http';
import type * as Request from './interface/request';
import type * as Response from './interface/response';

const modulePath = '/order';

/**
 * @description: checkout 查询用户订单物流
 */
export function getOrderLogistics(params: Request.OrderNumber) {
  http.setAuthorization(Cookies.get('token') as string);
  return http.client<Response.OrderLogisticsList>(`${modulePath}/get_logistics_track`, {
    params,
  });
}

/**
 * @description: 支付成功跳转订单完成页
 * @param {string} orderNumber
 * @return {*}
 */
export function getOrderComplete(orderNumber: string) {
  return http.client<Response.OrderComplete>(`${modulePath}/get_order_success_detail?orderNumber=${orderNumber}`);
}

/**
 * @description: 游客支付成功跳转订单完成页
 * @param {string} orderNumber
 * @return {*}
 */
export function getVisitorOrderComplete(orderNumber: string) {
  return http.client<Response.OrderComplete>(`${modulePath}/get_visitor_order_success_detail?orderNumber=${orderNumber}`);
}

export default {};
